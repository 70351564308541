import React, { useState, useEffect } from 'react';
import '../App.css';

const SpeedReading = () => {
    const [text, setText] = useState('');
    const [url, setUrl] = useState('');
    const [words, setWords] = useState([]);
    const [index, setIndex] = useState(0);
    const [isReading, setIsReading] = useState(false);
    const [wpm, setWpm] = useState(200);
    const [isLoading, setIsLoading] = useState(false);
    const [isPaused, setIsPaused] = useState(false);

    useEffect(() => {
        let timer;
        if (isReading && !isPaused) {
            timer = setInterval(() => {
                setIndex(prevIndex => {
                    const newIndex = prevIndex + 1;
                    if (newIndex >= words.length) {
                        setIsReading(false);
                        return prevIndex;
                    }
                    return newIndex;
                });
            }, 60000 / wpm);
        }
        return () => clearInterval(timer);
    }, [isReading, isPaused, wpm, words]);

    const handleStart = () => {
        setWords(text.split(' '));
        setIndex(0);
        setIsReading(true);
        setIsPaused(false);
    };

    const handleStop = () => {
        setIsReading(false);
        setIndex(0);
    };

    const handlePauseResume = () => {
        setIsPaused(prevPaused => !prevPaused);
    };

    const fetchTextFromUrl = async () => {
        try {
            setIsLoading(true); // Show loading indicator
            const response = await fetch(`https://api.allorigins.win/get?url=${encodeURIComponent(url)}`);
            if (!response.ok) {
                throw new Error('Failed to fetch the webpage.');
            }
            const data = await response.json();
            const parser = new DOMParser();
            const doc = parser.parseFromString(data.contents, 'text/html');
            const elements = doc.querySelectorAll('h1, h2, h3, p');
            const extractedText = Array.from(elements).map(el => el.textContent).join(' ');
            setText(extractedText);
        } catch (error) {
            console.error('Error fetching text:', error);
            alert('Failed to fetch text from the provided URL. Please check the URL and try again.');
        } finally {
            setIsLoading(false); // Hide loading indicator
        }
    };

    return (
        <div className="speed-reading-container">
            <h1>Speed Reading</h1>
            <textarea
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder="Paste your text here or fetch from a URL..."
                rows={10}
            />
            <div className="controls">
                <input
                    type="text"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    placeholder="Enter a URL to fetch text..."
                />
                <button onClick={fetchTextFromUrl} disabled={isLoading}>
                    Fetch Text
                </button>
                {isLoading && <div className="loading-indicator">Loading...</div>}
            </div>
            <div className="controls">
                <input
                    type="number"
                    value={wpm}
                    onChange={(e) => setWpm(e.target.value)}
                    placeholder="Words per minute"
                />
                <button onClick={handleStart} disabled={isReading && !isPaused}>
                    Start
                </button>
                <button onClick={handleStop} disabled={!isReading}>
                    Stop
                </button>
                <button onClick={handlePauseResume} disabled={!isReading}>
                    {isPaused ? 'Resume' : 'Pause'}
                </button>
            </div>
            {isReading && (
                <div className="word-display">
                    <h2>{words[index]}</h2>
                </div>
            )}
        </div>
    );
};

export default SpeedReading;