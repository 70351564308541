import React from 'react';
import ExpandableSection from './ExpandableSection';
import '../App.css';

const GitHubBestPractices = ({ expandSection }) => (
  <ExpandableSection
    title="GitHub Best Practices"
    id="github-best-practices"
    expandSection={expandSection}
  >
    <h2>GitHub Best Practices</h2>
    
    <h3>Basic Git Commands</h3>
    
    <h4>Pull</h4>
    <p>The <code>git pull</code> command fetches changes from a remote repository and merges them into your current branch. This is often used to update your local branch with changes from a remote repository.</p>
    <pre><code>git pull origin main</code></pre>
    
    <h4>Push</h4>
    <p>The <code>git push</code> command uploads your local commits to a remote repository. This is how you send your changes to a repository for others to see and use.</p>
    <pre><code>git push origin main</code></pre>
    
    <h4>Commit</h4>
    <p>The <code>git commit</code> command records changes to the repository. Commits are snapshots of your project at specific points in time.</p>
    <pre><code>git commit -m "Add feature X"</code></pre>
    <p><strong>Commit Message Best Practices:</strong></p>
    <ul>
      <li><strong>Be Descriptive:</strong> Clearly describe what the commit does.</li>
      <li><strong>Use the Present Tense:</strong> Use present tense to describe the changes (e.g., "Add feature" instead of "Added feature").</li>
      <li><strong>Limit the Subject Line:</strong> Keep the subject line under 50 characters.</li>
      <li><strong>Include a Body if Necessary:</strong> If the change is complex, include a detailed body explaining why the change was made and any additional context.</li>
    </ul>

    <h4>Merge</h4>
    <p>The <code>git merge</code> command integrates changes from different branches. Merging combines the contents of the specified branch with the current branch.</p>
    <pre><code>git merge feature-branch</code></pre>
    <p><strong>Merge Best Practices:</strong></p>
    <ul>
      <li><strong>Resolve Conflicts:</strong> Address any conflicts that arise during the merge.</li>
      <li><strong>Test Before Merging:</strong> Ensure the code works correctly before merging it into the main branch.</li>
      <li><strong>Use Pull Requests:</strong> Use pull requests for merging to facilitate code review and discussion.</li>
    </ul>

    <h4>Squash</h4>
    <p>The <code>git rebase</code> command with the <code>-i</code> flag allows you to combine multiple commits into one. This is useful for cleaning up commit history.</p>
    <pre><code>git rebase -i HEAD~n</code></pre>
    <p><strong>Squash Best Practices:</strong></p>
    <ul>
      <li><strong>Use Squash for Clean History:</strong> Squash commits to create a cleaner, more understandable commit history.</li>
      <li><strong>Rebase Instead of Merge:</strong> Use rebase to apply your changes on top of the latest changes from the main branch.</li>
    </ul>

    <h4>Branches</h4>
    <p>Branches allow you to work on different features or issues in isolation. The <code>git checkout</code> command with the <code>-b</code> flag creates a new branch and switches to it.</p>
    <pre><code>git checkout -b new-branch</code></pre>
    <p><strong>Branching Best Practices:</strong></p>
    <ul>
      <li><strong>Use Descriptive Names:</strong> Name branches clearly to indicate their purpose (e.g., <code>feature/add-login</code>, <code>bugfix/fix-login-error</code>).</li>
      <li><strong>Keep Branches Short-lived:</strong> Merge branches back into the main branch as soon as the work is complete to avoid divergence.</li>
      <li><strong>Regularly Sync with Main:</strong> Regularly pull changes from the main branch to keep your branch up-to-date and reduce merge conflicts.</li>
    </ul>

    <h3>Merge Conflicts</h3>
    
    <h4>What Are Merge Conflicts?</h4>
    <p>Merge conflicts occur when Git is unable to automatically reconcile differences between two commits. This typically happens when changes are made to the same line of a file or when one branch deletes a file that another branch modifies.</p>
    
    <h4>How to Resolve Merge Conflicts</h4>
    <ul>
      <li><strong>Identify Conflicts:</strong> Git will mark the conflicts in the file with conflict markers. These look like this:</li>
      <pre>
        <code>
          &lt;&lt;&lt;&lt;&lt;&lt;&lt; HEAD // Your changes =======  // Changes from the branch being merged &gt;&gt;&gt;&gt;&gt;&gt;&gt; branch-name
        </code>
      </pre>
      <li><strong>Edit the Conflicts:</strong> Manually edit the marked sections to resolve the conflicts. Remove the conflict markers and make sure the code is as it should be.</li>
      <li><strong>Stage the Resolved Files:</strong> Once you've resolved the conflicts, stage the files using:</li>
      <pre><code>git add &lt;filename&gt;</code></pre>
      <li><strong>Complete the Merge:</strong> After staging the resolved files, complete the merge with:</li>
      <pre><code>git commit</code></pre>
      <li><strong>Test Thoroughly:</strong> Ensure that your changes work as expected and that the merge did not introduce any issues.</li>
    </ul>

    <h4>Best Practices for Avoiding Merge Conflicts</h4>
    <ul>
      <li><strong>Communicate:</strong> Coordinate with your team to avoid working on the same parts of the code simultaneously.</li>
      <li><strong>Frequent Pulls:</strong> Regularly pull changes from the main branch to minimize divergence and potential conflicts.</li>
      <li><strong>Small, Frequent Commits:</strong> Make small, incremental changes rather than large, sweeping changes to reduce the chance of conflicts.</li>
      <li><strong>Use Feature Branches:</strong> Isolate work in feature branches and merge back into the main branch frequently.</li>
    </ul>

    <h3>Best Practices</h3>
    
    <h4>Peer Review</h4>
    <ul>
      <li><strong>Code Reviews:</strong> Conduct thorough reviews to maintain code quality. Look for logic errors, code consistency, and adherence to best practices.</li>
      <li><strong>Comments:</strong> Provide constructive feedback. Focus on the code, not the person, and offer suggestions for improvement.</li>
      <li><strong>Approval:</strong> Ensure that changes are approved by at least one reviewer before merging. This helps catch potential issues early.</li>
      <li><strong>Review Process:</strong> Establish a standard review process to ensure consistency. Use checklists to ensure all aspects of the code are reviewed.</li>
    </ul>
    
    <h4>Commit Messages</h4>
    <ul>
      <li><strong>Clarity:</strong> Write clear and descriptive commit messages. Avoid vague messages like "fix" or "update."</li>
      <li><strong>Format:</strong> Follow the convention: <code>type(scope): subject</code>. For example, <code>feat(login): add user authentication</code>.</li>
      <li><strong>Types:</strong> Use types like <code>feat</code> (feature), <code>fix</code> (bug fix), <code>docs</code> (documentation), <code>style</code> (formatting), <code>refactor</code> (code changes), <code>test</code> (adding or modifying tests), and <code>chore</code> (maintenance).</li>
    </ul>
    
    <h4>Branching Strategy</h4>
    <ul>
      <li><strong>Feature Branches:</strong> Create a new branch for each feature or bugfix. This isolates work and makes it easier to manage and review.</li>
      <li><strong>Merge Requests:</strong> Use pull requests for merging. This facilitates discussion, review, and documentation of changes.</li>
      <li><strong>Trunk-Based Development:</strong> Keep the main branch stable and release-ready at all times. Regularly merge changes from feature branches into the main branch.</li>
    </ul>
    
    <h4>Continuous Integration</h4>
    <ul>
      <li><strong>Automate Testing:</strong> Ensure that all tests pass before merging. Use CI tools to automate the testing process and catch issues early.</li>
      <li><strong>Linting:</strong> Use linters to maintain code consistency. Linting tools can catch syntax errors and enforce coding standards.</li>
      <li><strong>Build Automation:</strong> Automate the build process to ensure that the code can be compiled and deployed successfully.</li>
    </ul>
    
    <h4>Collaboration</h4>
    <ul>
      <li><strong>Regular Pulls:</strong> Regularly pull changes from the main branch to stay up-to-date. This helps reduce merge conflicts and ensures your branch is in sync.</li>
      <li><strong>Communication:</strong> Communicate changes and issues promptly. Use issue tracking and project management tools to keep the team informed.</li>
      <li><strong>Documentation:</strong> Document your code and processes. Good documentation helps new team members get up to speed and makes it easier to maintain the codebase.</li>
    </ul>
  </ExpandableSection>
);

export default GitHubBestPractices;
