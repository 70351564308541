import React from 'react';
import Navigation from '../components/Navigation';
import CookiesBanner from '../components/CookiesBanner';
import DonationSection from '../components/DonationSection';
import OMDbRandomFilm from '../components/OMDbRandomFilm';

function OMDbRandomFilmApp() {
  return (
    <div className="App">
      <Navigation />
      <OMDbRandomFilm />
      <CookiesBanner />
      <DonationSection />
    </div>
  );
}

export default OMDbRandomFilmApp;