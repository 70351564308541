import React from 'react';
import ExpandableSection from './ExpandableSection';
import '../App.css';

const LinuxOS = ({ expandSection }) => (
  <ExpandableSection
    title="Linux Operating Systems: Rocky Linux, Ubuntu, and Debian"
    id="linux-os"
    expandSection={expandSection}
  >
    <h2>Linux Operating Systems Overview</h2>
    <p>Linux operating systems offer various options tailored to different needs. This section compares Rocky Linux, Ubuntu, and Debian, focusing on their strengths and weaknesses. Additionally, we will cover key aspects of system monitoring using the `top` command, a crucial tool for performance analysis.</p>
    
    <h3>Rocky Linux</h3>
    <p><strong>Pros:</strong></p>
    <ul>
      <li><strong>Enterprise-Grade Stability:</strong> Designed as a RHEL (Red Hat Enterprise Linux) clone, Rocky Linux provides enterprise-level stability and reliability.</li>
      <li><strong>Community-Driven:</strong> Developed and maintained by a dedicated community, ensuring ongoing support and updates.</li>
      <li><strong>Free and Open Source:</strong> Available at no cost with a commitment to open-source principles.</li>
    </ul>
    <p><strong>Cons:</strong></p>
    <ul>
      <li><strong>New to Market:</strong> As a newer distribution, it might have fewer third-party applications and integrations compared to more established options like Debian.</li>
      <li><strong>Limited Commercial Support:</strong> While community support is strong, there might be fewer options for commercial support compared to RHEL.</li>
    </ul>
    
    <h3>Ubuntu</h3>
    <p><strong>Pros:</strong></p>
    <ul>
      <li><strong>User-Friendly:</strong> Known for its ease of use, making it a popular choice for both beginners and advanced users.</li>
      <li><strong>Regular Releases:</strong> Provides a predictable release cycle with Long-Term Support (LTS) versions that receive updates for five years.</li>
      <li><strong>Extensive Community and Documentation:</strong> A large user base and comprehensive documentation make troubleshooting and learning easier.</li>
    </ul>
    <p><strong>Cons:</strong></p>
    <ul>
      <li><strong>Resource Usage:</strong> Some users find that Ubuntu’s default installation uses more system resources compared to other distributions.</li>
      <li><strong>Potential Bloat:</strong> The default installation may include unnecessary software for users who prefer a minimal setup.</li>
    </ul>
    
    <h3>Debian</h3>
    <p><strong>Pros:</strong></p>
    <ul>
      <li><strong>Stability:</strong> Known for its stability and robustness, Debian is often used as a base for other distributions like Ubuntu.</li>
      <li><strong>Long-Term Support:</strong> Debian offers a long support cycle for its stable releases, making it suitable for production environments.</li>
      <li><strong>Wide Range of Packages:</strong> Debian’s package repository includes a vast number of software packages.</li>
    </ul>
    <p><strong>Cons:</strong></p>
    <ul>
      <li><strong>Less Frequent Updates:</strong> Debian’s stable release cycle may not include the latest software versions compared to rolling releases.</li>
      <li><strong>Initial Setup:</strong> Some users find Debian’s initial setup and configuration more involved compared to user-friendly distributions like Ubuntu.</li>
    </ul>

    <h3>Understanding the `top` Command</h3>
    <p>The `top` command is essential for real-time monitoring of system performance. It provides a dynamic view of system processes, CPU usage, memory usage, and other metrics crucial for system administration.</p>
    
    <h4>Key Metrics in `top`</h4>
    <h5>1. System Information (SI) and Hardware Interrupts (HI)</h5>
    <p>The `top` command displays information about system interrupts, including soft and hard interrupts, which are critical for understanding system performance and responsiveness.</p>
    
    <h6>Soft Interrupts (SI)</h6>
    <p><strong>Soft Interrupts:</strong> These are software-driven interrupts often related to system calls and network processing. In `top`, they are shown in the CPU utilization summary.</p>
    
    <pre><code>Cpu(s):  1.0%us,  0.5%sy,  0.0%ni, 98.0%id,  0.3%wa,  0.0%hi,  0.2%si,  0.0%st</code></pre>
    
    <h6>Hard Interrupts (HI)</h6>
    <p><strong>Hard Interrupts:</strong> These are generated by hardware devices to signal the CPU about important events. They are part of the CPU usage metrics in `top` and indicate how much CPU time is spent handling these interrupts.</p>
    
    <pre><code>Cpu(s):  1.0%us,  0.5%sy,  0.0%ni, 98.0%id,  0.3%wa,  0.0%hi,  0.2%si,  0.0%st</code></pre>
    
    <h5>2. Load Average</h5>
    <p><strong>Load Average:</strong> This metric represents the average number of processes in the run queue over specific time intervals. The load average is shown as three numbers: the average over the last 1, 5, and 15 minutes.</p>
    
    <pre><code>top - 10:00:00 up 10 days,  2:30,  1 user,  load average: 0.25, 0.30, 0.40</code></pre>
    
    <ul>
      <li><strong>1-Minute Load Average:</strong> Reflects the system’s load over the last minute.</li>
      <li><strong>5-Minute Load Average:</strong> Shows the system’s load over the last 5 minutes.</li>
      <li><strong>15-Minute Load Average:</strong> Indicates the system’s load over the last 15 minutes.</li>
    </ul>
    
    <p><strong>Interpreting Load Averages:</strong> A load average equal to the number of CPU cores indicates full utilization. Values significantly above this may suggest overloading, while values below suggest underutilization.</p>

    <h3>Linux File System Hierarchy</h3>
    <p>The Linux file system follows a hierarchical structure, which is vital for effective file management and system navigation.</p>
    
    <ul>
      <li><strong>/etc:</strong> Configuration files for the system and applications.</li>
      <li><strong>/var:</strong> Variable files such as logs and databases.</li>
      <li><strong>/usr:</strong> User-related programs and data, including applications and libraries.</li>
      <li><strong>/home:</strong> User personal files and configurations.</li>
    </ul>
    
    <h3>Package Management</h3>
    <h4>Ubuntu</h4>
    <p><strong>APT Commands:</strong> Ubuntu uses `apt` for package management.</p>
    <pre>
      <code>sudo apt update  # Update package list</code>
      <code>sudo apt install &lt;package-name&gt;  # Install a package</code>
      <code>sudo apt upgrade  # Upgrade all packages</code>
      <code>sudo apt remove &lt;package-name&gt;  # Remove a package</code>
    </pre>
    
    <h4>Rocky Linux</h4>
    <p><strong>YUM/DNF Commands:</strong> Rocky Linux uses `yum` (for older versions) or `dnf` (for newer versions) for package management.</p>
    <pre>
      <code>sudo yum update  # Update package list (older versions)</code>
      <code>sudo dnf update  # Update package list (newer versions)</code>
      <code>sudo yum install &lt;package-name&gt;  # Install a package (older versions)</code>
      <code>sudo dnf install &lt;package-name&gt;  # Install a package (newer versions)</code>
      <code>sudo yum remove &lt;package-name&gt;  # Remove a package (older versions)</code>
      <code>sudo dnf remove &lt;package-name&gt;  # Remove a package (newer versions)</code>
    </pre>
    
    <h3>System Services and Daemons</h3>
    <p>Managing system services is essential for maintaining a stable and secure system. Use `systemctl` for systems with `systemd` or `service` for older SysVinit systems.</p>
    
    <pre>
      <code>sudo systemctl start &lt;service&gt;  # Start a service</code>
      <code>sudo systemctl stop &lt;service&gt;  # Stop a service</code>
      <code>sudo systemctl restart &lt;service&gt;  # Restart a service</code>
      <code>sudo systemctl status &lt;service&gt;  # Check the status of a service</code>
    </pre>
  </ExpandableSection>
);

export default LinuxOS;
