import React, { useState } from 'react';
import axios from 'axios';
import '../App.css';

const OMDbSearch = () => {
    const [keywords, setKeywords] = useState('');
    const [film, setFilm] = useState(null);
    const [films, setFilms] = useState([]);
    const [selectedFilm, setSelectedFilm] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const omdbApiKey = process.env.REACT_APP_OMDB_API_KEY;

    // Function to generate a random word
    const generateRandomWord = async () => {
        try {
            const response = await axios.get('https://random-word-api.herokuapp.com/word?number=1');
            return response.data[0];
        } catch (error) {
            console.error('Error fetching random word:', error);
            return 'movie'; // Fallback to a default word if there is an error
        }
    };

    const fetchFilms = async () => {
        setLoading(true);
        setError('');
        setFilms([]);
        setFilm(null);
        setSelectedFilm(null);

        // Use random word if no keywords are provided
        const searchKeywords = keywords.trim() === '' ? await generateRandomWord() : keywords;

        try {
            const query = `https://www.omdbapi.com/?apikey=${omdbApiKey}&s=${encodeURIComponent(searchKeywords)}&type=movie`;
            const response = await axios.get(query);

            if (response.data.Response === 'True') {
                const films = response.data.Search;
                if (films.length > 0) {
                    setFilms(films);
                    // Pick a random film from the search results
                    const randomFilm = films[Math.floor(Math.random() * films.length)];
                    setSelectedFilm(randomFilm);
                    // Fetch detailed information about the selected film
                    await fetchFilmDetails(randomFilm.imdbID);
                } else {
                    setError('No films found matching your criteria.');
                }
            } else {
                setError('No films found matching your criteria.');
            }
        } catch (error) {
            console.error('Error fetching films:', error);
            setError('An error occurred while fetching the films. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const fetchFilmDetails = async (imdbID) => {
        try {
            const query = `https://www.omdbapi.com/?apikey=${omdbApiKey}&i=${imdbID}`;
            const response = await axios.get(query);
            if (response.data.Response === 'True') {
                setFilm(response.data);
            } else {
                setError('Failed to fetch details for the selected film.');
            }
        } catch (error) {
            console.error('Error fetching film details:', error);
            setError('An error occurred while fetching the film details. Please try again.');
        }
    };

    return (
        <div className="omdb-search-container">
            <h1>Find a Random Film</h1>
            <div className="filters">
                <input
                    type="text"
                    value={keywords}
                    onChange={(e) => setKeywords(e.target.value)}
                    placeholder="Enter keywords (e.g., Adventure, Space)"
                />
                <button onClick={fetchFilms} disabled={loading}>
                    {loading ? 'Searching...' : 'Find Random Film'}
                </button>
            </div>
            {error && <p className="error">{error}</p>}
            {film && (
                <div className="film-result">
                    <h2>{film.Title} ({film.Year})</h2>
                    <p><strong>Genre:</strong> {film.Genre || 'N/A'}</p>
                    <p><strong>Cast:</strong> {film.Actors || 'N/A'}</p>
                    <p><strong>Plot:</strong> {film.Plot || 'N/A'}</p>
                    <img src={film.Poster} alt={`${film.Title} Poster`} />
                </div>
            )}
            {films.length > 0 && !film && (
                <div className="film-list">
                    <h3>Other Films Found:</h3>
                    <ul>
                        {films.map((item) => (
                            <li key={item.imdbID} onClick={() => fetchFilmDetails(item.imdbID)}>
                                <h4>{item.Title} ({item.Year})</h4>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default OMDbSearch;