import React, { useState } from 'react';
import '../App.css';

const ExpandableSection = ({ title, id, expandSection, children }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  React.useEffect(() => {
    if (expandSection === id) {
      setIsExpanded(true);
    }
  }, [expandSection, id]);

  return (
    <section id={id}>
      <h3 onClick={handleToggle} style={{ cursor: 'pointer' }}>
        {title}
      </h3>
      {isExpanded && <div>{children}</div>}
    </section>
  );
};

export default ExpandableSection;
