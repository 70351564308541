import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import BitrateCalculatorPage from './pages/BitrateCalculatorPage';
import PcapExtractorPage from './pages/PcapExtractorPage';
import SpeedReadingApp from './pages/SpeedReading';
import OMDbRandomFilmApp from './pages/OMDbRandomFilmPage';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import ProfilePage from './pages/ProfilePage';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/bitratecalculator" element={<BitrateCalculatorPage />} />
          <Route path="/SpeedReading" element={<SpeedReadingApp />} />
          <Route path="/OMDbRandomFilmApp" element={<OMDbRandomFilmApp />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          {/* Protected routes */}
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <ProfilePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pcapextractor"
            element={
              <ProtectedRoute>
                <PcapExtractorPage />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;