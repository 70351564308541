import React, { useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import './BitrateCalculator.css';

const BitrateCalculator = () => {
  const [bitrate, setBitrate] = useState('27');
  const [numInputs, setNumInputs] = useState('1');
  const [numOutputs, setNumOutputs] = useState('1');
  const [numStreams, setNumStreams] = useState('1');
  const [duration, setDuration] = useState('30');
  const [dataRateUnit, setDataRateUnit] = useState('Mbps');
  const [throughputUnit, setThroughputUnit] = useState('Mbps');
  const [storageUnit, setStorageUnit] = useState('MB');
  const [totalThroughput, setTotalThroughput] = useState(null);
  const [fileSizeSingle, setFileSizeSingle] = useState(null);
  const [fileSizeAll, setFileSizeAll] = useState(null);

  // Conversion factors
  const dataRateConversion = {
    kbps: 1,
    Mbps: 1000,
    Gbps: 1000000,
  };

  const storageConversion = {
    KB: 1,
    MB: 1024,
    GB: 1024 * 1024,
  };

  const handleCalculate = () => {
    // Error handling
    if (
      isNaN(bitrate) ||
      isNaN(numInputs) ||
      isNaN(numOutputs) ||
      isNaN(numStreams) ||
      isNaN(duration) ||
      bitrate <= 0 ||
      numInputs < 0 ||
      numOutputs < 0 ||
      numStreams <= 0 ||
      duration <= 0
    ) {
      alert('Please enter valid positive numerical values for all fields.');
      return;
    }

    // Convert bitrate to kbps for calculation
    const bitrateInKbps = parseFloat(bitrate) * dataRateConversion[dataRateUnit];

    // Calculate total throughput in kbps
    const throughputInKbps = bitrateInKbps * (parseFloat(numInputs) + parseFloat(numOutputs)) * parseFloat(numStreams);

    // Calculate estimated file size for a single output and all outputs in KB (assuming bitrate is in kbps, time is in seconds)
    const fileSizeSingleInKB = (bitrateInKbps / 8) * parseFloat(duration);
    const fileSizeAllInKB = fileSizeSingleInKB * parseFloat(numOutputs) * parseFloat(numStreams);

    // Convert throughput and file sizes to the selected units
    const throughputConverted = throughputInKbps / dataRateConversion[throughputUnit];
    const fileSizeSingleConverted = fileSizeSingleInKB / storageConversion[storageUnit];
    const fileSizeAllConverted = fileSizeAllInKB / storageConversion[storageUnit];

    setTotalThroughput(throughputConverted.toFixed(2));
    setFileSizeSingle(fileSizeSingleConverted.toFixed(2));
    setFileSizeAll(fileSizeAllConverted.toFixed(2));
  };

  return (
    <div>
      <h2>Bitrate Calculator</h2>
      <div>
        <label>
          Bitrate:
          <input 
            type="text" 
            value={bitrate} 
            onChange={(e) => setBitrate(e.target.value)} 
            data-tip="Enter the bitrate value (e.g., 500 kbps)"
          />
          <ReactTooltip />
          <select 
            value={dataRateUnit} 
            onChange={(e) => setDataRateUnit(e.target.value)}
            data-tip="Select the unit of bitrate"
          >
            <option value="kbps">kbps</option>
            <option value="Mbps">Mbps</option>
            <option value="Gbps">Gbps</option>
          </select>
          <ReactTooltip />
        </label>
      </div>
      <div>
        <label>
          Number of Inputs:
          <input 
            type="text" 
            value={numInputs} 
            onChange={(e) => setNumInputs(e.target.value)} 
            data-tip="Enter the number of input streams"
          />
          <ReactTooltip />
        </label>
      </div>
      <div>
        <label>
          Number of Outputs:
          <input 
            type="text" 
            value={numOutputs} 
            onChange={(e) => setNumOutputs(e.target.value)} 
            data-tip="Enter the number of output streams"
          />
          <ReactTooltip />
        </label>
      </div>
      <div>
        <label>
          Number of Streams:
          <input 
            type="text" 
            value={numStreams} 
            onChange={(e) => setNumStreams(e.target.value)} 
            data-tip="Enter the number of streams"
          />
          <ReactTooltip />
        </label>
      </div>
      <div>
        <label>
          Duration (seconds):
          <input 
            type="text" 
            value={duration} 
            onChange={(e) => setDuration(e.target.value)} 
            data-tip="Enter the duration of the video in seconds"
          />
          <ReactTooltip />
        </label>
      </div>
      <button onClick={handleCalculate}>Calculate</button>
      
      {totalThroughput !== null && (
        <div>
          <h3>
            Total Throughput: {totalThroughput} 
            <select 
              value={throughputUnit} 
              onChange={(e) => setThroughputUnit(e.target.value)}
              data-tip="Select the unit for total throughput"
              style={{ marginLeft: '8px' }}
            >
              <option value="kbps">kbps</option>
              <option value="Mbps">Mbps</option>
              <option value="Gbps">Gbps</option>
            </select>
            <ReactTooltip />
          </h3>
        </div>
      )}
      {fileSizeSingle !== null && (
        <div>
          <h3>
            Estimated File Size (Single Output): {fileSizeSingle} 
            <select 
              value={storageUnit} 
              onChange={(e) => setStorageUnit(e.target.value)}
              data-tip="Select the unit for file size"
              style={{ marginLeft: '8px' }}
            >
              <option value="KB">KB</option>
              <option value="MB">MB</option>
              <option value="GB">GB</option>
            </select>
            <ReactTooltip />
          </h3>
        </div>
      )}
      {fileSizeAll !== null && (
        <div>
          <h3>
            Estimated File Size (All Outputs): {fileSizeAll} 
            <select 
              value={storageUnit} 
              onChange={(e) => setStorageUnit(e.target.value)}
              data-tip="Select the unit for file size"
              style={{ marginLeft: '8px' }}
            >
              <option value="KB">KB</option>
              <option value="MB">MB</option>
              <option value="GB">GB</option>
            </select>
            <ReactTooltip />
          </h3>
        </div>
      )}
    </div>
  );
};

export default BitrateCalculator;
