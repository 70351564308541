import React from 'react';
import ExpandableSection from './ExpandableSection';
import '../App.css';

const ScriptingTools = ({ expandSection }) => (
  <ExpandableSection
    title="Scripting Tools: Ansible, Terraform, and CloudFormation"
    id="scripting-tools"
    expandSection={expandSection}
  >
    <p><strong>Ansible:</strong></p>
    <ul>
      <li><strong>Pros:</strong> Agentless, Simple YAML syntax, Large community support.</li>
      <li><strong>Cons:</strong> Performance may be slower for large deployments, Limited state management.</li>
    </ul>
    <p><strong>Terraform:</strong></p>
    <ul>
      <li><strong>Pros:</strong> Declarative language, State management, Multi-cloud support.</li>
      <li><strong>Cons:</strong> Requires understanding of HCL, State file management can be complex.</li>
    </ul>
    <p><strong>CloudFormation:</strong></p>
    <ul>
      <li><strong>Pros:</strong> Native to AWS, Integrated with AWS services, Supports nested stacks.</li>
      <li><strong>Cons:</strong> AWS-specific, Complex syntax, Limited to AWS.</li>
    </ul>

    <h3>Challenges in Learning Scripting: A Comprehensive Assessment</h3>
    <p>Learning scripting can be both rewarding and challenging. Whether you're delving into Bash, Python, or any other scripting language, there are common hurdles that learners often face. Understanding these challenges and learning how to overcome them can significantly enhance your scripting journey. This assessment explores these challenges, offers practical hints, and provides code examples to help you navigate the learning process effectively.</p>

    <h3>1. Understanding the Basics</h3>
    <p><strong>Challenge:</strong> Grasping the fundamental concepts of scripting languages can be daunting for beginners. Concepts such as variables, data types, and control structures are crucial, but they can be overwhelming at first.</p>
    <p><strong>Hint:</strong> Start with a clear and simple script. For example, a basic "Hello, World!" script can help you get accustomed to the syntax and structure of the scripting language.</p>
    <pre>
      <code>
        print("Hello, World!")  
      # Python
      </code>
    </pre>
    <pre>
      <code>
        echo "Hello, World!"  
        # Bash
      </code>
    </pre>

    <h3>2. Syntax and Language Specifics</h3>
    <p><strong>Challenge:</strong> Each scripting language has its own syntax, which can be confusing. For instance, Python relies heavily on indentation, while languages like JavaScript use braces.</p>
    <p><strong>Hint:</strong> Use coding style guides and linters to maintain consistency. For example, flake8 for Python or eslint for JavaScript can help catch syntax errors early.</p>
    <pre>
        <code>
          if True:
            print("This is correctly indented")  
            # Python
        </code>
    </pre>
    <pre>
        <code>
            if (true) {
                console.log("This is correctly bracketed")
                // JavaScript
            }
        </code>
    </pre>

    <h3>3. Debugging and Error Handling</h3>
    <p><strong>Challenge:</strong> Debugging scripts can be challenging, especially when errors are not clearly reported. Learning how to read and interpret error messages is a key skill.</p>
    <p><strong>Hint:</strong> Utilize debugging tools and add logging to your scripts. For instance, Python's pdb and Bash's set -x can be invaluable.</p>
    <pre>
      <code>
        import pdb; pdb.set_trace()  
        # Python
      </code>
    </pre>
    <pre>
      <code>
        set -x  
        # Bash
      </code>
    </pre>

    <h3>4. Handling External Libraries and Dependencies</h3>
    <p><strong>Challenge:</strong> Managing external libraries and dependencies can be tricky, especially for beginners. Conflicts and version mismatches are common issues.</p>
    <p><strong>Hint:</strong> Use package managers like pip for Python or npm for JavaScript to manage dependencies. Always specify versions in your configuration files.</p>
    <pre>
      <code>
          pip install requests  
          # Python
      </code>
    </pre>
    <h3>5. Learning Through Practice</h3>
    <p><strong>Challenge:</strong> Theory alone is not enough; practical experience is crucial. Many learners struggle with applying concepts to real-world problems.</p>
    <p><strong>Hint:</strong> Work on small projects and gradually increase their complexity. Try to automate simple tasks or solve small problems using scripts.</p>
    <pre>
      <code>
        import os

        def rename_files(directory):
          for filename in os.listdir(directory):
            if filename.endswith(".txt"):
              new_name = filename.replace(".txt", "_backup.txt")
              os.rename(os.path.join(directory, filename), os.path.join(directory, new_name))

        rename_files("/path/to/directory")  
        # Python
      </code>
    </pre>

    <h3>6. Understanding Advanced Concepts</h3>
    <p><strong>Challenge:</strong> As you advance, you'll encounter more complex topics such as multi-threading, network scripting, and system interactions.</p>
    <p><strong>Hint:</strong> Take a modular approach to learning. Study advanced topics separately and then integrate them into your projects.</p>
    <pre>
          <code>
          import threading
          def print_numbers():
            for i in range(10):
              print(i)
          thread = threading.Thread(target=print_numbers)
          thread.start()  # Python
          </code>
    </pre>

          <p><strong>Conclusion:</strong> Learning scripting is a journey filled with challenges, but understanding these hurdles and employing practical strategies can make the process smoother. By starting with the basics, managing dependencies effectively, and continuously practicing, you'll develop the skills needed to excel in scripting. Keep experimenting, learning, and growing.</p>
  </ExpandableSection>
);

export default ScriptingTools;
