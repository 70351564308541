import React from 'react';
import ExpandableSection from './ExpandableSection';
import '../App.css';

const CookiesAndLocalStorage = ({ expandSection }) => (
  <ExpandableSection
    title="Understanding Cookies and Local Storage"
    id="CookiesAndLocalStorage"
    expandSection={expandSection}
  >
    <div>
      <p>In modern web development, cookies and local storage are essential tools...</p>
      
      <h3>What Are Cookies?</h3>
      <p>Cookies are small text files that a web server sends to a user's web browser...</p>

      <h3>Types of Cookies</h3>
      <ul>
        <li><strong>Session Cookies:</strong> Temporary cookies that are deleted when the user closes their browser...</li>
        <li><strong>Persistent Cookies:</strong> These cookies remain on the user's device even after the browser is closed...</li>
        <li><strong>Secure Cookies:</strong> Cookies that can only be transmitted over secure HTTPS connections...</li>
        <li><strong>HttpOnly Cookies:</strong> Cookies that are not accessible via JavaScript...</li>
      </ul>

      <h3>Correct Usage of Cookies</h3>
      <p>Cookies are most commonly used for session management. They store session identifiers...</p>

      <h3>Potential Misuses of Cookies</h3>
      <p>Cookies can be used to track users across different websites without their consent...</p>

      <h3>What Is Local Storage?</h3>
      <p>Local storage is a feature of the Web Storage API that allows web applications to store data locally...</p>

      <h3>Correct Usage of Local Storage</h3>
      <p>Local storage is well-suited for storing user preferences, such as theme settings...</p>

      <h3>Potential Misuses of Local Storage</h3>
      <p>Local storage is not a secure place for storing sensitive information...</p>

      <h3>Cookies vs. Local Storage</h3>
      <table>
        <thead>
          <tr>
            <th>Feature</th>
            <th>Cookies</th>
            <th>Local Storage</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Size Limit</td>
            <td>~4KB per cookie</td>
            <td>~5-10MB per domain</td>
          </tr>
          <tr>
            <td>Data Persistence</td>
            <td>Session or expiration-based</td>
            <td>Persistent until explicitly deleted</td>
          </tr>
          <tr>
            <td>Transmission</td>
            <td>Sent with every HTTP request</td>
            <td>Not sent automatically with requests, only accessed via JS</td>
          </tr>
          <tr>
            <td>Security</td>
            <td>Can be secured with HttpOnly and Secure flags</td>
            <td>Vulnerable to XSS, no built-in security features</td>
          </tr>
          <tr>
            <td>Use Cases</td>
            <td>Session management, tracking, remembering user preferences</td>
            <td>Storing large data, caching, offline usage</td>
          </tr>
        </tbody>
      </table>

      <h3>Best Practices for Using Cookies and Local Storage</h3>
      <ul>
        <li>Minimize Sensitive Data Storage</li>
        <li>Implement Secure Cookies</li>
        <li>Set Expiration Dates Wisely</li>
        <li>Be Transparent with Users</li>
        <li>Regularly Audit Data</li>
      </ul>

      <h3>Malicious Uses of Cookies and Local Storage</h3>
      <p>Cross-Site Scripting (XSS) Attacks: Attackers can inject malicious scripts into a web page...</p>

      <h3>Conclusion</h3>
      <p>Cookies and local storage are powerful tools in web development that can significantly enhance...</p>
    </div>
  </ExpandableSection>
);

export default CookiesAndLocalStorage;
