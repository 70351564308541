import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import '../App.css';

const Navigation = () => {
  const { user, logout } = useContext(AuthContext);

  return (
    <nav>
      <ul>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/BitrateCalculator">Bitrate Calculator</Link></li>
        <li><Link to="/SpeedReading">Spead Reading</Link></li>
        <li><Link to="/OMDbRandomFilmApp"> Film Selector</Link></li>
        {user ? (
          <>
            <li><Link to="/PcapExtractor">PCAP Extractor</Link></li>
            <li><Link to="/profile">Profile</Link></li>
            <li><button onClick={logout}>Logout</button></li>
          </>
        ) : (
          <>
          <li><Link to="/login">Login</Link></li>
          <li><Link to="/Register">Register</Link></li>
          </>
        )}
      </ul>
    </nav>
  );
};

export default Navigation;
