import React from 'react';
import '../App.css';
import paypalLogo from '../assets/paypal-logo.png'; // Ensure you have the logo in this path
import githubLogo from '../assets/github-logo.png'; // Ensure you have the logo in this path
import paypalqr from '../assets/paypalqr.png';

const DonationSection = () => (
  <div className="donation-section">
    <h2>Support Me</h2>
    <p>If you find my work interesting, please consider supporting me.</p>
    <div className="donation-buttons">
      <a href="https://www.paypal.com/donate?hosted_button_id=5HC2J3MMETH66" target="_blank" rel="noopener noreferrer">
        <img src={paypalLogo} alt="Donate with PayPal" className="donation-logo" />
      </a>
      <a href="https://github.com/phillipsjdan" target="_blank" rel="noopener noreferrer">
        <img src={githubLogo} alt="GitHub Profile" className="donation-logo" />
      </a>
      <a href="https://www.paypal.com/donate?hosted_button_id=5HC2J3MMETH66" target="_blank" rel="noopener noreferrer">
        <img src={paypalqr} alt="Donate with PayPal" className="donation-logo" />
      </a>
      
    </div>
  </div>
);

export default DonationSection;
