import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useContext(AuthContext);

  if (loading) {
    return <div>Loading...</div>; // Display a loading indicator while checking authentication state
  }

  // If the user is authenticated, render the children; otherwise, redirect to login
  return user ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;