import React from 'react';
import Navigation from '../components/Navigation';
import CookiesBanner from '../components/CookiesBanner';
import DonationSection from '../components/DonationSection';
import SpeedReading from '../components/SpeedReading';

function SpeedReadingApp() {
  return (
    <div className="App">
      <Navigation />
      <SpeedReading />
      <CookiesBanner />
      <DonationSection />
    </div>
  );
}

export default SpeedReadingApp;