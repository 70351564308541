import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import DonationSection from '../components/DonationSection';
import Navigation from '../components/Navigation';

const ProfilePage = () => {
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch user profile data
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/api/profile', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserData(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
        if (error.response && error.response.status === 401) {
          // Redirect to login if unauthorized
          navigate('/login');
        }
      }
    };

    fetchUserData();
  }, [navigate]);

  const handleLogout = () => {
    // Remove the token from local storage and redirect to login
    localStorage.removeItem('token');
    navigate('/login');
  };

  if (!userData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
    <Navigation />
      <h1>Welcome, {userData.username}!</h1>
      <p>Email: {userData.email}</p>
      <button onClick={handleLogout}>Logout</button>
    <DonationSection />
    </div>
  );
};

export default ProfilePage;
