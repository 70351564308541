import React, { useEffect, useState } from 'react';
import ExpandableSection from './ExpandableSection';
import axios from 'axios';

const truncate = (value) => (value.length > 20 ? value.slice(0, 20) + '...' : value);

const InterestingStats = ({ expandSection }) => {
  const [userInfo, setUserInfo] = useState({});
  const [cookies, setCookies] = useState([]);
  const [localStorageItems, setLocalStorageItems] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axios.get('/api/user-info');
        setUserInfo(response.data);
      } catch (error) {
        console.error('Error fetching user info:', error);
        setError('There was an error fetching your information.');
      }
    };

    fetchUserInfo();
  }, []);

  useEffect(() => {
    // Fetch and parse cookies
    const cookiesArray = document.cookie.split(';').map(cookie => {
      const [name, value] = cookie.split('=');
      return { name: name.trim(), value: decodeURIComponent(value) };
    });

    // Retrieve all items from localStorage
    const localStorageData = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      const value = localStorage.getItem(key);
      localStorageData.push({ name: key, value });
    }

    setCookies(cookiesArray);
    setLocalStorageItems(localStorageData);
  }, []);

  return (
    <ExpandableSection
      title="Interesting Browser stats"
      id="interestingstats"
      expandSection={expandSection}
    >
      <h2>Interesting Stats</h2>
      <p>This section is designed to demonstrate some of the information a website can track about you. This includes information such as your public IP address, language, browser, and operating system.</p>
      {error && <p>{error}</p>}
      <table>
        <thead>
          <tr>
            <th>Attribute</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>IP Address</td>
            <td>{userInfo.ipAddress}</td>
          </tr>
          <tr>
            <td>User Agent</td>
            <td>{userInfo.userAgent}</td>
          </tr>
          <tr>
            <td>Referer</td>
            <td>{userInfo.referer}</td>
          </tr>
          <tr>
            <td>Language</td>
            <td>{userInfo.language}</td>
          </tr>
        </tbody>
      </table>

      <div>
        <h3>Stored Cookies</h3>
        {cookies.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Cookie Name</th>
                <th>Cookie Value</th>
              </tr>
            </thead>
            <tbody>
              {cookies.map((item, index) => (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>{truncate(item.value)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No cookies found.</p>
        )}
      </div>

      <div>
        <h3>Local Storage Items</h3>
        {localStorageItems.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Local Storage Name</th>
                <th>Local Storage Value</th>
              </tr>
            </thead>
            <tbody>
              {localStorageItems.map((item, index) => (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>{truncate(item.value)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No local storage items found.</p>
        )}
      </div>
    </ExpandableSection>
  );
};

export default InterestingStats;