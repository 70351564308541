import React, { useState, useEffect } from 'react';
import './CookiesBanner.css'; // Make sure you have this CSS file for styling

const CookiesBanner = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    // Check local storage to see if the banner has been acknowledged
    const acknowledged = localStorage.getItem('cookiesAcknowledged');
    if (!acknowledged) {
      setVisible(true);
    }
  }, []);

  const handleAcknowledge = () => {
    // Set local storage to remember the acknowledgment
    localStorage.setItem('cookiesAcknowledged', 'true');
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <div className="cookies-banner">
      <p>We use cookies to enhance your experience. By continuing to visit this site, you agree to our use of cookies.</p>
      <button onClick={handleAcknowledge}>Got it!</button>
    </div>
  );
};

export default CookiesBanner;
