import React from 'react';
import '../App.css';

const AboutMe = () => (
  <section id="about-me">
    <h2>About Me</h2>
    <p>I'm a hobbyist who enjoys scripting, networking, and IoT. This page showcases some of the tools and technologies I work with. One point of interest about this site is that i've created it mostly using ChatGPT. I was particulary interested in how AI interprets the current state of systems I experiment with. I will note that using these AI tools is certianly a skill of its own and certainly requires a well thought through statement when looking for specific results.</p>
  </section>
);

export default AboutMe;
