import React, { useState } from 'react';
import axios from 'axios';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const PcapExtractor = () => {
  const [file, setFile] = useState(null);
  const [encryptionKey, setEncryptionKey] = useState('');
  const [encryptionType, setEncryptionType] = useState('none');
  const [downloadUrl, setDownloadUrl] = useState('');

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleEncryptionKeyChange = (event) => {
    setEncryptionKey(event.target.value);
  };

  const handleEncryptionTypeChange = (event) => {
    setEncryptionType(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!file) {
      alert('Please upload a PCAP file.');
      return;
    }

    const formData = new FormData();
    formData.append('pcapfile', file);
    formData.append('encryptionKey', encryptionKey);
    formData.append('encryptionType', encryptionType);

    try {
      const response = await axios.post('/api/uploads', formData, {
        responseType: 'blob', // Important for file downloads
      });

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      setDownloadUrl(downloadUrl);
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Error processing the file.');
    }
  };

  return (
    <div className="PcapParser">
      <h1>PCAP to MPEG-TS Converter</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <input
            type="file"
            accept=".pcap"
            onChange={handleFileChange}
            data-tip="Upload your PCAP file containing RTP/UDP/SRT packets."
          />
          <ReactTooltip />
        </div>
        <div>
          <label>
            Encryption Key:
            <input
              type="text"
              value={encryptionKey}
              onChange={handleEncryptionKeyChange}
              data-tip="Provide the encryption key if SRT encryption is used."
            />
          </label>
          <ReactTooltip />
        </div>
        <div>
          <label>
            Encryption Type:
            <select value={encryptionType} onChange={handleEncryptionTypeChange}>
              <option value="none">None</option>
              <option value="AES128">AES128</option>
              <option value="AES196">AES196</option>
              <option value="AES256">AES256</option>
            </select>
          </label>
          <ReactTooltip />
        </div>
        <button type="submit">Upload and Convert</button>
      </form>
      {downloadUrl && (
        <div>
          <h2>Download Converted File</h2>
          <a href={downloadUrl} download="output.ts">
            Click here to download the MPEG-TS file
          </a>
        </div>
      )}
    </div>
  );
};

export default PcapExtractor;
