import React from 'react';
import PcapExtractor from '../components/PcapExtractor';
import Navigation from '../components/Navigation';
import CookiesBanner from '../components/CookiesBanner';
import DonationSection from '../components/DonationSection';
import '../App.css';

const PcapExtractorPage = () => {
  return (
    <div className="PcapExtractorPage">
      <Navigation />
      <PcapExtractor />
      <CookiesBanner />
      <DonationSection />
    </div>
  );
};

export default PcapExtractorPage;

