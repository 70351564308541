import React from 'react';
import AboutMe from '../components/AboutMe';
import Navigation from '../components/Navigation';
import ScriptingTools from '../components/ScriptingTools';
import Hypervisors from '../components/Hypervisors';
import LinuxOS from '../components/LinuxOS';
import CICDPipelines from '../components/CICDPipelines';
import IoTSection from '../components/IoTSection';
import GitHubBestPractices from '../components/GitHubBestPractices';
import InterestingStats from '../components/InterestingStats';
import CookiesAndLocalStorage from '../components/CookiesAndLocalStorage';
import CookiesBanner from '../components/CookiesBanner';
import DonationSection from '../components/DonationSection';

import '../App.css';

const Home = () => {
  return (
    <div className="Home">
      <Navigation />
      <AboutMe />

      <ScriptingTools />
      <Hypervisors />
      <LinuxOS />
      <CICDPipelines />
      <IoTSection />
      <GitHubBestPractices />
      <CookiesAndLocalStorage />
      <InterestingStats />

      <CookiesBanner />
      <DonationSection />
    </div>
  );
};

export default Home;
